import Swal from 'sweetalert2'

export const confirmAlert = (title, message = null) => {
  return Swal.fire({
    title: title,
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Si, continuar',
    cancelButtonText: 'Cancelar',
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-outline-danger ml-1',
    },
    buttonsStyling: false,
  })
}

// Alert with input
export const inputAlert = (title, message = null) => {
  return Swal.fire({
    title: title,
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Si, continuar',
    cancelButtonText: 'Cancelar',
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-outline-danger ml-1',
    },
    buttonsStyling: false,
    input: 'text',
    inputValidator: (value) => {
      if (!value) {
        return 'Debes ingresar un valor'
      }
    },
  })
}

export const generateCustomProductId = (length = 20) => {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return `custom-${result}`
}

export const downloadBlob = (data, nameFile) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", nameFile);
  document.body.appendChild(link);
  link.click();
};

export const perPageBaseOptions = [10, 20, 30, 40, 50, 100]

export const perPageBaseRegisters = 10
